import { useTranslation } from "react-i18next";
import Section from "../../../components/modal/section/Section";
import piggyImage from "../../../assets/img/piggy-bank.png";
import piggyImageLarge from "../../../assets/img/piggy-bank@2x.png";
import "./comissionPlanSection.scss"
import ReactMarkdown from "react-markdown";
import { getTranslationKeyPrefix, Product } from "../../home/ourBrands";
interface SectionData {
    title: string;
    listItems: string[];
}

export default function ComissionPlanSection({ product }: { product: Product }) {
    const { t } = useTranslation();
    const sectionData: SectionData = t(`${getTranslationKeyPrefix(product)}.sectionCommissionPlan`, { returnObjects: true });
    return (
        <div className="commission-plan section-background-light" >
            <Section title={sectionData.title}>
                <div className="container pt-md-1 fw-bold">
                    <div className="row pt-md-1 align-items-center">
                        <div className="col-sm-7 col-md-6">
                            <ul className="text-center list-no-bullets commision-plan-list">
                                    <li className="py-4">
                                        {sectionData.listItems[0]}
                                    </li>
                                    <li className="py-4">
                                        <ReactMarkdown>{sectionData.listItems[1]}</ReactMarkdown>
                                    </li>
                            </ul>
                        </div>
                        <div className="col-sm-5 col-md-6">
                            <div className="mt-0">
                                <img className="comission-plan-image" srcSet={`${piggyImage} 614w, ${piggyImageLarge} 1228w`} sizes="auto" width="614" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </Section>
        </ div>
    );

}
