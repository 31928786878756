import { useTranslation } from "react-i18next";
import List from "../../../components/modal/list/List";
import Section from "../../../components/modal/section/Section";
import { getTranslationKeyPrefix, Product } from "../../home/ourBrands";


interface SectionData {
    title: string;
    listItems: string[];
}

export default function WhySection({ product }: { product: Product }) {
    const { t } = useTranslation();
    const sectionData: SectionData = t(`${getTranslationKeyPrefix(product)}.sectionWhy`, { returnObjects: true });
    return (
        <div className="section-background-light" >
            <div className="container">
                <Section title={sectionData.title}>
                    <div className="row justify-content-center">
                        <div className="col-sm-10">
                            <List items={sectionData.listItems} classes="text-center list-no-bullets why-section-list" liClasses="fw-bold"/>
                        </div>
                    </div>
                </Section>
            </div>
        </ div>
    );
}