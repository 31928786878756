import FooterImage from "../../assets/img/trustseal.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./footer.scss";
import { environmentVariables } from "../../environment";

interface FooterData {
    copyrights: string[];
    termLinkText: string;
}

export default function Footer() {
    const { t } = useTranslation();
    const FOOTER_TRANSLATION_KEY = "footerSection";
    const footerData: FooterData = t(FOOTER_TRANSLATION_KEY, { returnObjects: true });

    return (
        <footer className="footer">
            <div className="main-container">
                <span className="footer__copyright">
                    {footerData.copyrights?.map((copyright : string, index: number) => (
                        <span key={copyright}>
                            {index !== 0 && <br/>} {copyright}
                        </span>
                    ))}
                </span>
    
                <div className="footer__badge">
                    <img src={FooterImage} alt=""/>
                </div>
    
                <ul className="footer-links my-3">
                    <li>
                        <Link to={`${environmentVariables.PUBLIC_URL}/terms`} >{footerData.termLinkText}</Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}