import { useTranslation } from "react-i18next";
import { changeLanguage, Languages } from "../../i18n";

export const LanguageToggleButton: React.FC<{}> = () => {
  const { i18n } = useTranslation();
  return (
    <button
      onClick={() => {
        changeLanguage(
          i18n.language === Languages.English
            ? Languages.Spanish
            : Languages.English
        );
      }}
      className="btn btn-link language-toggle d-contents"
    >
      <span className="d-none d-md-inline-block">
        {i18n.language === Languages.English ? "Español" : "English"}
      </span>
      <span className="d-md-none">
        {i18n.language === Languages.English ? "ES" : "EN"}
      </span>
    </button>
  );
};
