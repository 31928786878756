import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { environmentVariables } from "./environment";
import { getValue as getLocalStorageValue, setValue as setLocalStorageValue } from "./LocalStorageManager";

export enum TranslationNamespaces {
  Main = "main",
}

export enum Languages {
  English = "en",
  Spanish = "es",
}

export const LOCALE_KEY = 'locale';
export const DEFAULT_LANGUAGE = Languages.English;
const setupLanguage = (): Languages => {
  const localStorageLocale = getLocalStorageValue(LOCALE_KEY);
  if (!localStorageLocale) {
    setLocalStorageValue(LOCALE_KEY, DEFAULT_LANGUAGE);
    return DEFAULT_LANGUAGE;
  }
  return localStorageLocale;
}

export const changeLanguage = (language: Languages) => {
  setLocalStorageValue(LOCALE_KEY, language);
  return i18n.changeLanguage(language);
}

export const initConfig = {
  lng: setupLanguage(), // if you're using a language detector, do not define the lng option
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  ns: "main",
  react: {
    useSuspense: false,
  },
  backend: {
    queryStringParams: { hash: environmentVariables.REACT_APP_HEAD_COMMIT_HASH },
  },
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init(initConfig);

export default i18n;
