import { RefObject, useEffect, useState } from "react";

interface NavItem { 
  text: string | null; 
  sectionId: string; 
  nodeName: string;
}

export enum Tag {
  H3 = "H3",
  P = "P"
};

export const useMarkdownHeadingIndexing = (
  markdownWrapperDivRef: RefObject<HTMLDivElement>,
  nodeNamesToBeIndexed = [Tag.H3, Tag.P]
) => {
  const [sections, setSections] = useState<NavItem[]>([]);
  useEffect(() => {
    let observer: MutationObserver;

    if (markdownWrapperDivRef.current) {
      observer = new MutationObserver((mutationsList) => {
        if (mutationsList.some((mutation) => mutation.type === "childList")) {

          const elements = Array.from(
            markdownWrapperDivRef.current!.children
          ).filter((elem) => 
            nodeNamesToBeIndexed.includes(elem.nodeName as Tag)
          );

          let h3SectionCount = 0;
          let subSectionCount = 0;
          const getSectionId = (elem: Element, index: number) => {
            if (elem.nodeName === Tag.H3) {
              h3SectionCount++;
              subSectionCount = 0;
              return `section-${h3SectionCount}`;
            }
            if (elem.nodeName === Tag.P && h3SectionCount !== 0) {
              subSectionCount++;
              return `section-${h3SectionCount}-${subSectionCount}`;
            }
            return `element-${index}`;
          }

          const navItems: NavItem[] = [];
          
          elements.forEach((elem, index) => {
            const sectionId = getSectionId(elem, index);
            elem.setAttribute("id", sectionId);
            navItems.push({
              text: elem.textContent,
              sectionId: elem.id,
              nodeName: elem.nodeName,
            })
          });

          setSections(navItems);
        }
      });
      observer.observe(markdownWrapperDivRef.current, {
        attributes: false,
        childList: true,
        subtree: false,
      });
    }
    return () => observer.disconnect();
  }, [markdownWrapperDivRef, nodeNamesToBeIndexed]);
  return { sections };
};
