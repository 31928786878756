import LogoPng from "../../assets/img/logo.png";
import LogoSmallPng from "../../assets/img/logo-small.png";
import "./header.scss";
import HamburgerIcon from "./HamburgerIcon";
import { useTranslation } from "react-i18next";
import SideNav from "./SideNav";
import { useState } from "react";
import { NAV_ITEMS } from "./constants";
import "./header.scss";
import { LanguageToggleButton } from "./LanguageButton";
import { Link } from "react-router-dom";
import { environmentVariables } from '../../environment'
import { Languages } from "../../i18n";

const getLangQueryParam = (language: Languages) => {
  if (language === Languages.English) {
    return "1";
  } else if (language === Languages.Spanish) {
    return "3";
  }
  return "";
};

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [showSideNav, setShowSideNav] = useState(false);
  const langQueryParam = getLangQueryParam(i18n.language as Languages);
  return (
    <>
      <header className="sticky-top">
        <div className="main-container px-md-none">
          <div className="d-flex justify-content-between">
            <div className="col d-lg-none me-2">
              <button
                className="btn btn-link btn-left-nav-menu-toggle"
                onClick={() => setShowSideNav(true)}
              >
                <HamburgerIcon></HamburgerIcon>
              </button>
            </div>
            <div className="col text-center d-lg-flex flex-lg-grow-0">
              <div className="d-inline-block header-logo-wrapper">
                <Link to={environmentVariables.PUBLIC_URL}>
                  <img
                    className="d-md-none"
                    src={LogoSmallPng}
                    alt="Rush Affiliates"
                    width="70"
                  ></img>
                  <img
                    className="d-none d-md-inline-block"
                    src={LogoPng}
                    alt="Rush Affiliates"
                    width="218"
                  ></img>
                </Link>
              </div>
            </div>
            <div className="col navigation-links-wrapper align-items-center justify-content-center d-none d-lg-flex mx-4">
              <ul className="nav d-flex justify-content-evenly flex-grow-1 flex-shrink-0">
                {NAV_ITEMS.map(({ titleTranslationKey, to, ElemType }) => (
                  <li
                    key={titleTranslationKey}
                    className="col nav-item text-nowrap text-center flex-grow-0"
                  >
                    <ElemType className="nav-link px-0" to={`${environmentVariables.PUBLIC_URL}${to}`}>
                      {t(titleTranslationKey)}
                    </ElemType>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col header-buttons justify-content-end align-items-center d-flex flex-lg-grow-0">
              <div className="d-flex align-items-center">
              <a
                  className="btn btn-secondary ms-2 ms-md-0 me-2 me-md-4 text-nowrap"
                  href={`https://partners.rush-affiliates.com/?lang=${langQueryParam}`}
                >
                  {t("LogIn")}
                </a>
                <LanguageToggleButton />
              </div>
            </div>
          </div>
        </div>
      </header>
      <SideNav
        showSideNav={showSideNav}
        setShowSideNav={setShowSideNav}
      ></SideNav>
    </>
  );
};
