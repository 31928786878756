import { useContext, useEffect, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";
import { SectionsRefs, SectionsRefsContext } from "./contexts";

export const ADD_SECTION_REF_ACTION_TYPE = "ADD_SECTION_REF";
export const ACTION_NOT_RECOGNIZED_MESSAGE = "Action not recognized.";

export const reducerFunc = (
  state: SectionsRefs,
  action: { type: string; payload: any }
): SectionsRefs => {
  switch (action.type) {
    case ADD_SECTION_REF_ACTION_TYPE:
      return { ...state, ...action.payload };
    default:
      throw new Error(ACTION_NOT_RECOGNIZED_MESSAGE);
  }
};

export const useScrollToSection = () => {
  const location = useLocation();
  const initialState = {};
  const [refs, dispatch] = useReducer(reducerFunc, initialState);
  useEffect(() => {
    const { hash } = location;
    const locationSectionId = hash.replace("#", "");
    if (hash && Object.keys(refs).includes(locationSectionId)) {
      const elem = refs[locationSectionId];
      elem.scrollIntoView();
    }
  }, [refs, location]);
  return { refs, dispatch };
};

export const useSectionReference = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const { dispatch } = useContext(SectionsRefsContext);
  useEffect(() => {
    if (sectionRef.current && dispatch) {
      dispatch({
        type: ADD_SECTION_REF_ACTION_TYPE,
        payload: { [sectionRef.current.id]: sectionRef.current },
      });
    }
  }, [dispatch]);
  return { sectionRef };
};
