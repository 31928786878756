import { useTranslation } from "react-i18next";
import { TranslationNamespaces } from "../i18n";
import { MarkdownComponent } from "./MarkdownComponent";

export const TermsComponent = () => {
  const { i18n } = useTranslation([TranslationNamespaces.Main]);

  return (
    <main className="main">
      {i18n.language && <MarkdownComponent mdFileUrl={`/locales/${i18n.language}/terms.md`}></MarkdownComponent>}
    </main>
  );
};
