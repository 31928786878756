import { Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { environmentVariables } from "../../environment";
import { NAV_ITEMS } from "./constants";
import './sidenav.scss'

export const SideNav: React.FC<{
  showSideNav: boolean;
  setShowSideNav: Function;
}> = ({ showSideNav, setShowSideNav }) => {
  const { t } = useTranslation();

  return (
    <Offcanvas
      className="side-nav-offcanvas"
      show={showSideNav}
      onHide={() => setShowSideNav(false)}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="navbar-nav h-100 d-flex flex-column justify-content-center align-items-center">
          {NAV_ITEMS.map(({ titleTranslationKey, to }) => {
            const linkIncludesHash = to.includes("#");
            const linkCommonProps = {
              className: "nav-link",
              to: `${environmentVariables.PUBLIC_URL}${to}`,
              onClick: () => setShowSideNav(false),
            };
            const label = t(titleTranslationKey);
            return (
              <li key={titleTranslationKey} className="py-5">
                {linkIncludesHash && (
                  <HashLink
                    {...linkCommonProps}
                    //using a custom scrolling function with some delay cuz HashLink's default does not behave as expected in this context for some reason
                    scroll={(el: Element) =>
                      setTimeout(() => el.scrollIntoView(), 500)
                    }
                  >
                    {label}
                  </HashLink>
                )}
                {!linkIncludesHash && (
                  <Link {...linkCommonProps}>{label}</Link>
                )}
              </li>
            );
          })}
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SideNav;