import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import { TermsComponent } from './terms-and-conditions/TermsComponent';


export default function Root() {
    return (
        <Routes>
            <Route path="/terms" element={<TermsComponent />} />
            <Route path="*" element={<Home />} />
        </Routes>
    );
}
