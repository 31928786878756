export default function List({ items, classes, liClasses }: { items: string[], classes?: string, liClasses?: string }) {
    return (
        <ul className={classes}>
            {items.map((item: string) => (
                <li className={`py-4 ${liClasses}`} key={item}>
                    {item}
                </li>
            ))}
        </ul>
    )
}
