interface SectionProps {
    title?: string;
    children: any;
}

export default function Section({ title, children }: SectionProps) {
    return (
        <section className="mt-5 pb-4">
            <div className="text text-center">
                {title && <h2>{title}</h2>}
            </div>
            {children}
        </section >
    );
}