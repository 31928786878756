import { useTranslation } from "react-i18next";
import Section from "../../../../components/modal/section/Section";
import "./trustSection.scss";
import { SugarHouseImage, BetRiversImageCasino, BetRiversImageSportsBook } from "./TrustImage";
import List from "../../../../components/modal/list/List";
import { BETRIVERS_SPORTSBOOK_ONLY_LOCATIONS, BrandsKeys, getTranslationKeyPrefix, Product } from "../../../home/ourBrands";

interface SectionData {
    title: string;
    listItems: string[];
}

export default function TrustSection({ product }: { product: Product }) {
    const { t } = useTranslation();
    const sectionData: SectionData = t(`${getTranslationKeyPrefix(product)}.sectionTrust`, { returnObjects: true });
    return (
        <div className="section-background" >
            <Section>
                <div className="container pt-5">
                    <div className="row pt-5 align-items-center">
                        <div className="col-sm-7 col-md-6">
                            <h3 className="text-center trust-section-title">{sectionData.title}</h3>
                            <List items={sectionData.listItems} classes="text-start trust-section-list"/>
                        </div>
                        <div className="col-sm-5 col-md-6">
                            <div className="mt-0 section__image--macbook">
                                {product.brand === BrandsKeys.BETRIVERS && (
                                    BETRIVERS_SPORTSBOOK_ONLY_LOCATIONS.includes(product.location) ?
                                        <BetRiversImageSportsBook />
                                        : <BetRiversImageCasino />
                                )}
                                {product.brand === BrandsKeys.SUGARHOUSE && <SugarHouseImage />}
                            </div>
                        </div>
                    </div>
                </div>

            </Section>
        </ div>
    );
}