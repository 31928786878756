import { useTranslation } from "react-i18next";
import "./GetStarted.scss";

interface SectionStep {
    header: string;
    description: string | {
        preEmailText: string;
        emailAddress: string;
        postEmailText: string;
      };
}

interface SectionData {
    steps: SectionStep[];
    btnGetStarted: string;
}

export default function GetStarted () {
    const { t } = useTranslation();
    const GET_STARTED_TRANSLATION_KEY = "getStartedSection";
    const sectionData: SectionData = t(GET_STARTED_TRANSLATION_KEY, { returnObjects: true });
    return (
        <section className="section section--background-dark bg-dark get-started" id="starting-steps" >
            <div className="main-container mt-5">
                <div className="row pt-2 pb-5">
                    {sectionData.steps?.map(({ header, description }, index : number ) => (
                        <div key={header} className="col-sm mb-2">
                            <div className="row justify-content-center">
                                <div className="col-2 col-sm-8 col-lg-2 m-3 sm-text-center">
                                    <div className="bg-primary rounded-centered">
                                        <span className="vertalign h2" > {index+1} </span>
                                    </div>
                                </div>
                                <div className="col-8 mt-3">
                                    <div className="fs-5 fw-bolder mb-2 sm-text-center">
                                        {header}
                                    </div>
                                    <div className="description sm-text-center">
                                    {typeof description === "string" && description}
                                    {typeof description === "object" &&
                                        description.preEmailText &&
                                        description.emailAddress &&
                                        description.postEmailText && (
                                            <>
                                                {description.preEmailText}{" "}
                                                <a href={`mailto:${description.emailAddress}`}>
                                                    {description.emailAddress}
                                                </a>{" "}
                                                {description.postEmailText}
                                            </>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}