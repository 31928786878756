import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import slide1 from "../../assets/img/slide-1.png";
import slide2 from "../../assets/img/slide-2.png";
import slide3 from "../../assets/img/slide-3.png";
import "./slider.scss";

export const JOIN_NOW_HREF = 'https://partners.rush-affiliates.com/registration.asp';
export const JOIN_NOW_LABEL_TK = 'homeSlider.joinNowButtonLabel';

export const Slider = () => {
  const { t } = useTranslation();
  return (
    <div className="slider-wrapper px-4">
      <Carousel controls={false} interval={5000}>
        <Carousel.Item>
          <div className="main-container d-flex flex-column flex-sm-row align-items-center justify-content-center pb-5">
            <div className="col-sm col-lg-5 order-2 order-sm-1 text-center text-sm-start">
              <div className="heading">
                {t('homeSlider.slide1.heading')}
              </div>
              <p className="body mt-4 d-none d-sm-block">
              {t('homeSlider.slide1.body')}
              </p>
            </div>
            <div className="col-sm col-lg-7 order-1 order-sm-2 text-center">
              <img
                className="w-100 img-slide img-slide-1"
                src={slide1}
                alt=""
              />
            </div>
          </div>
          <Carousel.Caption> {t('homeSlider.slide1.caption')}</Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="main-container d-flex flex-column flex-sm-row align-items-center justify-content-center pb-5">
            <div className="col-sm col-lg-5 order-2 order-sm-1 text-center text-sm-start">
              <div className="heading heading-lg">
              {t('homeSlider.slide2.heading')}
              </div>
            </div>
            <div className="col-sm col-lg-7 order-1 order-sm-2 text-center">
              <img
                className="w-100 img-slide img-slide-2"
                src={slide2}
                alt=""
              />
            </div>
          </div>
          <Carousel.Caption>{t('homeSlider.slide2.caption')}</Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="main-container d-flex flex-column flex-sm-row align-items-center justify-content-center pb-5">
            <div className="col-sm col-lg-5 order-2 order-sm-1 text-center text-sm-start">
              <div className="heading heading-lg">
                {t('homeSlider.slide3.heading')}
              </div>
            </div>
            <div className="col-sm col-lg-7 order-1 order-sm-2 text-center">
              <img
                className="w-100 img-slide img-slide-3"
                src={slide3}
                alt=""
              />
            </div>
          </div>
          <Carousel.Caption>{t('homeSlider.slide3.caption')}</Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
