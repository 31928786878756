import { useTranslation } from "react-i18next";
import { useSectionReference } from "./useScrollToSection";
import "./GetInTouch.scss";

export default function GetInTouch() {
    const { t } = useTranslation();
    const GET_IN_TOUCH_TRANSLATION_KEY = "getInTouchSection";
    const sectionData: any = t(GET_IN_TOUCH_TRANSLATION_KEY, { returnObjects: true });

    const { sectionRef } = useSectionReference();
    return (
        <section ref={sectionRef} className="section bg-dark get-in-touch" id="contact" >
            <div className="text text-center">
                <h1 className="section__title">{sectionData.heading}</h1>
                <div className="col-11 col-md-8 col-lg-6 statement">
                    {sectionData.preEmailText} <a href="mailto:affiliates@rush-affiliates.com">affiliates@rush-affiliates.com</a> {sectionData.postEmailText}
                </div>
            </div>
        </section>
    );
}