import { createContext, Dispatch } from "react";

export interface SectionsRefs {
  [key: string]: HTMLElement;
}

export const SectionsRefsContext = createContext<
  {
    refs?: SectionsRefs,
    dispatch?: Dispatch<{
        type: string;
        payload: any;
      }>
    }
>({});
