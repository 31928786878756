import SHPhoneImageSmall from "../../../../assets/img/sugarhouse-phone@05x.png";
import SHPhoneImageLarge from "../../../../assets/img/sugarhouse-phone.png";
import BRPhoneCasinoImageSmall from "../../../../assets/img/betrivers-phone@05x.png";
import BRPhoneCasinoImageLarge from "../../../../assets/img/betrivers-phone.png";
import BRPhoneSportsBookImageSmall from "../../../../assets/img/betrivers-phone2@05x.png";
import BRPhoneSportsBookImageLarge from "../../../../assets/img/betrivers-phone2.png";

export const BR_IMG_ALT = "BetRivers App Homepage";
export function BetRiversImageCasino() {
    return (
        <TrustImage largeImage={BRPhoneCasinoImageLarge} smallImage={BRPhoneCasinoImageSmall} altText={BR_IMG_ALT} />
    )
}

export function BetRiversImageSportsBook() {
    return (
        <TrustImage largeImage={BRPhoneSportsBookImageLarge} smallImage={BRPhoneSportsBookImageSmall} altText={BR_IMG_ALT} />
    )
}

export const SH_IMAGE_ALT = "SugarHouse App Homepage";

export function SugarHouseImage() {
    return (
        <TrustImage largeImage={SHPhoneImageLarge} smallImage={SHPhoneImageSmall} altText={SH_IMAGE_ALT}/>
    )
}

function TrustImage({ largeImage, smallImage, altText }: { largeImage: string, smallImage: string, altText: string }) {
    return (
        <img className="section__image-image" src={largeImage} srcSet={`${smallImage} 562w, ${largeImage} 1124w`} sizes="auto" alt={altText} />
    );
}