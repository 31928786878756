import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { environmentVariables } from "../environment";
import { useMarkdownHeadingIndexing, Tag } from "./useMarkdownIndexing";

export const MarkdownComponent: React.FC<{ mdFileUrl: string }> = ({ mdFileUrl }) => {
  const [MDContent, setMDContent] = useState("");
  const markdownDivRef = useRef<HTMLDivElement>(null);
  const { sections } = useMarkdownHeadingIndexing(markdownDivRef);

  useEffect(() => {
    fetch(`${mdFileUrl}?hash=${environmentVariables.REACT_APP_HEAD_COMMIT_HASH}`)
      .then(response => response.text())
      .then(content => setMDContent(content));
  }, [mdFileUrl]);

  return (
    <div className="main-container px-2 mt-5">
      <div className="row">
        <div className="col-md-3">
          <ul className="list-no-bullets">
            {sections.filter(({ nodeName }) => nodeName === Tag.H3).map(({ text, sectionId }) => (
              <li key={sectionId}>
                <a href={`#${sectionId}`}>
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-9">
          <div ref={markdownDivRef}>
            <ReactMarkdown>{MDContent}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};
