import Root from './Root';
import { BrowserRouter } from 'react-router-dom';
import { Header } from './components/header';
import Footer from './components/footer';

function App() {

  return (
    <>
      <BrowserRouter>
        <Header />
          <Root />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
