import { Slider } from "../../components/homeSlider/Slider";
import { useScrollToSection } from "./useScrollToSection";
import { SectionsRefsContext } from "./contexts";
import GetInTouch from "./GetInTouch";
import GetStarted from "./GetStarted";
import { OurBrands } from "./ourBrands";

export default function Home() {
  const { refs, dispatch } = useScrollToSection();
  return (
    <SectionsRefsContext.Provider value={{ refs, dispatch }}>
      <main className="main">
        <Slider />
        <GetStarted />
        <OurBrands />
        <GetInTouch />
      </main>
    </SectionsRefsContext.Provider>
  );
}
