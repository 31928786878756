export enum BrandsKeys {
  BETRIVERS = "betrivers",
  SUGARHOUSE = "sugarhouse",
}

export enum LocationsKeys {
  ARIZONA = "AZ",
  COLORADO = "CO",
  ILLINOIS = "IL",
  INDIANA = "IN",
  MARYLAND = "MD",
  MICHIGAN = "MI",
  NEW_JERSEY = "NJ",
  OHIO = "OH",
  ONTARIO = "ON",
  PENNSYLVANIA = "PA",
  VIRGINIA = "VA",
  WEST_VIRGINIA = "WV",
  LOUISIANA = "LA",
}

export const PRODUCTS = {
  [BrandsKeys.BETRIVERS]: [
    LocationsKeys.ARIZONA,
    LocationsKeys.COLORADO,
    LocationsKeys.ILLINOIS,
    LocationsKeys.INDIANA,
    LocationsKeys.MARYLAND,
    LocationsKeys.MICHIGAN,
    LocationsKeys.OHIO,
    LocationsKeys.ONTARIO,
    LocationsKeys.PENNSYLVANIA,
    LocationsKeys.VIRGINIA,
    LocationsKeys.WEST_VIRGINIA,
    LocationsKeys.LOUISIANA,
    LocationsKeys.NEW_JERSEY,
  ],
  [BrandsKeys.SUGARHOUSE]: [
    LocationsKeys.PENNSYLVANIA,
  ],
};

export const BETRIVERS_SPORTSBOOK_ONLY_LOCATIONS = [
  LocationsKeys.ARIZONA,
  LocationsKeys.COLORADO,
  LocationsKeys.ILLINOIS,
  LocationsKeys.INDIANA,
  LocationsKeys.LOUISIANA,
  LocationsKeys.MARYLAND,
  LocationsKeys.OHIO,
  LocationsKeys.VIRGINIA,
];

export interface Product {
  brand: BrandsKeys;
  location: LocationsKeys;
}

export const getTranslationKeyPrefix = ({ brand, location }: Product) =>
  `brands.${brand}.locations.${location}`;