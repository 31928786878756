import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { getTranslationKeyPrefix, Product } from '../home/ourBrands';
import './brandModal.scss';
import ComissionPlanSection from './sections/ComissionPlanSection';
import MissionSection from './sections/MissionSection';
import TrustSection from './sections/trustSection/TrustSection';
import WhyPartnerSection from './sections/whyPartnerSection/WhyPartnerSection';
import WhySection from './sections/WhySection';

interface BrandModalProps {
    isModalOpen: boolean;
    onModalClose(): void;
    product: Product;
}

export default function BrandModal({ isModalOpen, onModalClose, product }: BrandModalProps) {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={onModalClose}
            contentLabel="Example Modal"
            ariaHideApp={false}
            className="brand-modal"
            bodyOpenClassName="ReactModal__Body--open"
            overlayClassName="brand-modal-overlay"
        >
            <div className="brand-modal-header sticky-top d-flex flex-row justify-content-between">
                <h4 className="brand-modal-title p-2 ms-lg-2 bd-highlight">{t(`${getTranslationKeyPrefix(product)}.title`)}</h4>
                <button type="button" className="brand-modal-close p-2 bd-highlight btn" onClick={onModalClose}><h4>x</h4></button>
            </div>
            <div className="brand-modal-scroll">
                <WhyPartnerSection product={product} />
                <TrustSection product={product} />
                <WhySection product={product} />
                <MissionSection product={product} />
                <ComissionPlanSection product={product} />
            </div>
        </Modal>
    )
}