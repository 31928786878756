import { useTranslation } from "react-i18next";
import Section from "../../../../components/modal/section/Section";
import Icons from "../../../../assets/svg/global.svg";
import "./whyPartnerSection.scss";
import List from "../../../../components/modal/list/List";
import { getTranslationKeyPrefix, Product } from "../../../home/ourBrands";

interface SectionData {
    title: string;
    description: string;
    forPartners: {
        title: string;
        listItems: string[];
    }
    forPlayers: {
        title: string;
        listItems: string[];
    }
}

function Subsection({ title, icon, listItems }: { title: string, icon: string, listItems: string[] }) {
    return (
        <div className="text text-center">
            <svg width="64" height="64" viewBox="0 0 64 64">
                <use xlinkHref={`${Icons}#${icon}`}></use>
            </svg>
            <h4 className="text-uppercase my-3">{title}</h4>
            <List items={listItems} classes="list-group list-group-flush" liClasses="list-group-item"/>
        </div>
    );
}

export default function WhyPartnerSection({ product }: { product: Product }) {
    const { t } = useTranslation();
    const sectionData: SectionData = t(`${getTranslationKeyPrefix(product)}.sectionWhyPartner`, { returnObjects: true });
    return (
        <Section title={sectionData.title}>
            <div className="text text-center fw-bold">{sectionData.description}</div>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-6 for-partners">
                        <Subsection title={sectionData.forPartners.title} icon="icon-suitcase" listItems={sectionData.forPartners.listItems} />
                    </div>
                    <div className="col-md-6 for-players">
                        <Subsection title={sectionData.forPlayers.title} icon="icon-mobile" listItems={sectionData.forPlayers.listItems} />
                    </div>
                </div>
            </div>
        </Section>
    );
}