import { useState } from "react";
import { useTranslation } from "react-i18next";
import BrandModal from "../../brandModal/BrandModal";
import sugarhouseLogo from "../../../assets/img/sugarhouse_logo.png";
import betriversLogo from "../../../assets/img/BR_Logo_New_400x317.png";
import "./our-brands.scss";
import { BrandsKeys, Product, PRODUCTS } from "./constants";
import { useSectionReference } from "../useScrollToSection";

export const OurBrands = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const ProductsList = ({ brand }: { brand: BrandsKeys }) => {
    return (
      <ul className="brands-list p-0 mt-3">
        {PRODUCTS[brand].sort().map((location) => (
          <li key={location}>
            <button
              onClick={() => {
                setIsModalOpen(true);
                setSelectedProduct({
                  brand,
                  location,
                });
              }}
              className="btn"
            >
              {t(`brands.locationLabels.${location}`)}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const { sectionRef } = useSectionReference();
  return (
    <section id="brands" ref={sectionRef}>
      <div className="main-container text-center py-5">
        <h2 className="section__title py-5"> {t("brands.sectionTitle")} </h2>
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-4">
            <div>
              <img
                className="brand-logo"
                src={betriversLogo}
                alt="BetRivers"
              ></img>
              <ProductsList brand={BrandsKeys.BETRIVERS} />
            </div>
          </div>
          <div className="col-12 col-md-4 mt-5 mt-md-0">
            <img
              className="brand-logo"
              src={sugarhouseLogo}
              alt="SugarHouser"
            ></img>
            <ProductsList brand={BrandsKeys.SUGARHOUSE} />
          </div>
        </div>
      </div>
      {selectedProduct && (
        <BrandModal
          isModalOpen={isModalOpen}
          onModalClose={() => setIsModalOpen(false)}
          product={selectedProduct}
        />
      )}
    </section>
  );
};
